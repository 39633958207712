import React,{ useEffect, useState } from "react";
import './style.css';

import { useLanguage } from '../../components/lang/langcontext.js';

export default function App() {
    const { language, texts, changeLanguage } = useLanguage();
    const Lang = texts[language];

    return (
    <div className="App AboutAppPage">
        <div className="AboutMain_Info">
            <div className="InfoText">
                <p className="AboutTitle">
                    {Lang.aboutus.abouttitle}
                </p>
                <p className="AboutText">
                    {Lang.aboutus.abouttext.text1}
                </p>

                <p className="AboutText">
                    {Lang.aboutus.abouttext.text2}
                </p>

                <p className="AboutText">
                    {Lang.aboutus.abouttext.text3}
                </p>

                <p className="AboutText">
                    {Lang.aboutus.abouttext.text4}
                </p>
            </div>
            {/**/}
        </div>
    </div>
    )
}