import React,{ useEffect, useState } from "react";
import MainHeader from './components/mainheader/index'
import MainFooter from './components/mainfooter/index'
//
import Home from './pages/Home/index'
import Services from './pages/Services/index'
import About from './pages/About/index'
import WorkWithUs from './pages/WorkWithUs/index'
import Contact from './pages/Contact/index'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './components/generalstyles/style.css'
import { Toaster } from 'react-hot-toast';
//import Cookies from 'js-cookie';
//import CircularProgress from '@mui/material/CircularProgress';

import FloatingContactIcons from './components/contacticons/index.js';
import { LanguageProvider } from './components/lang/langcontext.js';

function App() {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <Router>      

    <LanguageProvider>
      
      <FloatingContactIcons />

      <MainHeader />

      <Toaster
        position="bottom-right"
        reverseOrder={false}
      />
        
          <Routes>       
            <Route exact path="/" element={<Home/>} />
            {/*<Route exact path="/servicos" element={<Services/>} />*/}
            <Route exact path="/sobre" element={<About/>} />
            <Route exact path="/trabalhe-junto" element={<WorkWithUs/>} />
            <Route exact path="/contato" element={<Contact/>} />
            <Route exact path="*" element={<Navigate to="/" replace />}/>
          </Routes>
          
      <MainFooter />

    </LanguageProvider>

    </Router>
  );
}

export default App;
