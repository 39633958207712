import React,{ useEffect, useState } from "react";
import PhoneInput from 'react-phone-number-input';
import './style.css';
import '../../components/generalstyles/phoneinput_style.css';

import { useLanguage } from '../../components/lang/langcontext.js';

import ApiListJson from '../../components/infoapi/apilist.json';

export default function App() {
    const [phoneValue, setPhoneValue] = useState();

    const { language, texts, changeLanguage } = useLanguage();
    const Lang = texts[language];

    //

    useEffect(() => {
        const contactpage_googlemaps_master = document.querySelector("#contactpage_googlemaps-master");
        const contactpage_googlemaps_map = contactpage_googlemaps_master.querySelector(".mapapp");

        /*contactpage_googlemaps_map.unload = function(){
            contactpage_googlemaps_map.style.visibility = "hidden"
            contactpage_googlemaps_master.querySelector(".loader").classList.remove("hide");
        };*/

        contactpage_googlemaps_map.onload = function(){
            contactpage_googlemaps_map.style.visibility = "visible"
            contactpage_googlemaps_master.querySelector(".loader").classList.add("hide");
        };
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: '',
    });

    function changeInputEnabled(isEnabled) {
        const inputs = document.querySelectorAll('.contact_defaultinput');
        const inputs2 = document.querySelectorAll('.contact_defaultinput input');
        const inputs3 = document.querySelectorAll('.contact_defaultinput select');
      
        inputs.forEach(input => {
          input.disabled = !isEnabled;
        });
        inputs2.forEach(input2 => {
            input2.disabled = !isEnabled;
        });
        inputs3.forEach(input3 => {
            input3.disabled = !isEnabled;
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value, phone: phoneValue });
        document.getElementById("contact_mainform").classList.remove("formshowalert");
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const ApiURL = ApiListJson.contactapi;

        //
        const contact_mainform = document.getElementById("contact_mainform");
        const sendmessage_aboutbtn = document.getElementById("sendmessage_aboutbtn");
        const sendmessage_aboutbtn_loading = document.getElementById("sendmessage_aboutbtn_loading");
        const contact_formalert = contact_mainform.querySelector(".formalert");

        contact_mainform.classList.remove("formshowalert");
        contact_formalert.querySelector("p").textContent = "";
        contact_formalert.classList.remove("error");
        contact_formalert.classList.remove("alert");
        contact_formalert.classList.remove("success");

        changeInputEnabled(false);

        sendmessage_aboutbtn_loading.classList.remove("hide");
        sendmessage_aboutbtn.classList.add("hide");

        //console.log(formData);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', ApiURL);
        xhr.setRequestHeader('Content-Type', 'application/json');
        
        xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              const data = JSON.parse(xhr.responseText);
              if (data.success && data.message === 'msg_ok') {
                changeInputEnabled(true);
                contact_mainform.classList.add('formshowalert');
                contact_formalert.classList.add('success');
                contact_formalert.querySelector('p').textContent =
                  Lang.contact.formalerts.alert1;
                sendmessage_aboutbtn.classList.remove('hide');
                sendmessage_aboutbtn_loading.classList.add('hide');
              } else {
                changeInputEnabled(true);
                contact_mainform.classList.add('formshowalert');
                contact_formalert.classList.add('error');
                contact_formalert.querySelector('p').textContent =
                  Lang.contact.formalerts.alert3;
                sendmessage_aboutbtn.classList.remove('hide');
                sendmessage_aboutbtn_loading.classList.add('hide');
              }
            } else {
              changeInputEnabled(true);
              contact_mainform.classList.add('formshowalert');
              contact_formalert.classList.add('error');
              contact_formalert.querySelector('p').textContent =
                Lang.contact.formalerts.alert3;
              sendmessage_aboutbtn.classList.remove('hide');
              sendmessage_aboutbtn_loading.classList.add('hide');
            }
          }
        };
        
        const formDataToSend = JSON.stringify(formData);
        
        xhr.send(formDataToSend);
    };
    return (
    <div className="App ContactAppPage">
        <div className="ContactMasterPage">
            <div className="contactoptions">
                <div className="apptitle">
                    <p>{Lang.contact.pagetitle}</p>
                </div>
                {/**/}
                <a href="https://maps.app.goo.gl/6N3zf1CadjfYYr2Y9" target="_blank" rel="noreferrer" className="contactapp">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M12 2C7.589 2 4 5.589 4 9.995 3.971 16.44 11.696 21.784 12 22c0 0 8.029-5.56 8-12 0-4.411-3.589-8-8-8zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"></path>
                    </svg>
                    <p>Rua General Vasco Alves, 3371 - Santo Antônio Uruguaiana, RS</p>
                </a>
                <div className="contactapp">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="m20.487 17.14-4.065-3.696a1.001 1.001 0 0 0-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.859 3.513a1 1 0 0 0-1.391-.087l-2.17 1.861a1 1 0 0 0-.29.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.359-.008a.992.992 0 0 0 .648-.291l1.86-2.171a.997.997 0 0 0-.085-1.39z"></path>
                    </svg>
                    <p>(55) 3402-7516</p>
                </div>
                <div className="contactapp">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"></path>
                    </svg>
                    <p>(55) 3402-7516</p>
                </div>
                <div className="contactapp">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 4.7-8 5.334L4 8.7V6.297l8 5.333 8-5.333V8.7z"></path>
                    </svg>
                    <p>contato@workcomex.com.br</p>
                </div>
                <div className="contactapp">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 4.7-8 5.334L4 8.7V6.297l8 5.333 8-5.333V8.7z"></path>
                    </svg>
                    <p>paulo@workcomex.com.br</p>
                </div>
                <div className="contactapp">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M11.857 17.417c-2.947 0-4.294-1.524-4.294-2.641 0-.266.108-.521.298-.705a.946.946 0 0 1 .71-.264c1.261 0 .931 1.92 3.286 1.92 1.203 0 1.91-.736 1.91-1.425 0-.415-.234-.889-1.028-1.079l-2.629-.673c-2.111-.545-2.479-1.737-2.479-2.842 0-2.293 2.068-3.124 4.036-3.124 1.814 0 3.97 1.016 3.97 2.391 0 .592-.488.91-1.055.91-1.078 0-.897-1.536-3.063-1.536-1.077 0-1.645.513-1.645 1.23s.839.96 1.574 1.123l1.941.445c2.126.486 2.691 1.751 2.691 2.963 0 1.865-1.423 3.305-4.226 3.305m8.139-3.942c.086-.49.128-.986.128-1.482a8.472 8.472 0 0 0-2.952-6.474 8.211 8.211 0 0 0-6.788-1.856A4.818 4.818 0 0 0 7.935 3a4.954 4.954 0 0 0-4.27 2.519 5.103 5.103 0 0 0-.015 5.011 8.51 8.51 0 0 0 2.282 7.453 8.23 8.23 0 0 0 7.333 2.355 4.823 4.823 0 0 0 2.443.662 4.954 4.954 0 0 0 4.269-2.518 5.095 5.095 0 0 0 .016-5.009"></path>
                    </svg>
                    <p>contato@workcomex.com.br</p>
                </div>
                <div className="contactapp">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
                    </svg>
                    <p>@workcomex</p>
                </div>
                {/**/}
                
                <div id="contactpage_googlemaps-master" className="mapapp_master">
                    <span className="loader"></span>
                    <iframe className="mapapp" src={Lang.contact.googlemapurl} title="WorkComex Endereço" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            {/**/}
            <div className="contact-container">
                <form autoComplete="off" id="contact_mainform" onSubmit={handleSubmit}>
                    <div className="formalert">
                        <p></p>
                    </div>
                    <div className="form-group">

                    <div>
                        <span>
                            {Lang.contact.inputs.name.span}
                        </span>
                        <input
                            type="text"
                            name="name"
                            className="contact_defaultinput"
                            placeholder={Lang.contact.inputs.name.placeholder}
                            defaultValue={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div>
                        <span>
                            {Lang.contact.inputs.email.span}
                        </span>
                        <input
                            type="email"
                            name="email"
                            className="contact_defaultinput"
                            placeholder={Lang.contact.inputs.email.placeholder}
                            defaultValue={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div>
                        <span>
                            {Lang.contact.inputs.phone.span}
                        </span>
                        <PhoneInput
                            type="text"
                            name="phone"
                            className="contact_defaultinput"
                            placeholder={Lang.contact.inputs.phone.placeholder}
                            value={phoneValue}
                            onChange={setPhoneValue}
                            limitMaxLength={true}
                            defaultCountry={Lang.countrycode}
                            required
                        />
                    </div>
                    
                    <div>
                        <span>
                            {Lang.contact.inputs.company.span}
                        </span>
                        <input
                            type="text"
                            name="company"
                            className="contact_defaultinput"
                            placeholder={Lang.contact.inputs.company.placeholder}
                            defaultValue={formData.company}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    </div>
                    <div style={{gap: "0"}} className="form-group">
                        
                        <span>
                            {Lang.contact.inputs.message.span}
                        </span>
                        <textarea
                            name="message"
                            style={{minHeight: "120px", resize: "vertical"}}
                            placeholder={Lang.contact.inputs.message.placeholder}
                            className="contact_defaultinput"
                            defaultValue={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                        </div>

                        <button id="sendmessage_aboutbtn" className="contact_defaultinput" type="submit">
                            <span>{Lang.contact.inputs.sendbtn}</span>
                        </button>
                        <button id="sendmessage_aboutbtn_loading" className="hide contact_defaultinput" type="submit">
                            <span className="loader"></span>
                        </button>
                </form>
            </div>
        </div>

    </div>
    )
}