import React, { createContext, useContext, useState, useEffect } from 'react';
import texts from './languages.json';

const LanguageContext = createContext();

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export const LanguageProvider = ({ children }) => {
  //const detectedLanguage = navigator.language.toLowerCase();

  const [language, setLanguage] = useState('pt');

  /*useEffect(() => {
    const languageMap = {
      'en': 'en',
      'en-us': 'en',
      'en-gb': 'en',
      'en-ca': 'en',
      'en-au': 'en',
      'es': 'es',
      'es-es': 'es',
      'es-mx': 'es',
      'es-ar': 'es',
      'es-cl': 'es',
      'pt': 'pt',
      'pt-br': 'pt',
      'pt-pt': 'pt',
      'pt-ao': 'pt',
      'pt-mz': 'pt',
      'pt-gw': 'pt',
      // Espanhol
      'es-419': 'es',
      'es-co': 'es',
      'es-cr': 'es',
      'es-do': 'es',
      'es-ec': 'es',
      'es-gt': 'es',
      'es-hn': 'es',
      'es-ni': 'es',
      'es-pa': 'es',
      'es-pe': 'es',
      'es-pr': 'es',
      'es-py': 'es',
      'es-sv': 'es',
      'es-uy': 'es',
      'es-ve': 'es',
      // Inglês
      'en-150': 'en',
      'en-bz': 'en',
      'en-029': 'en',
      'en-bm': 'en',
      'en-gu': 'en',
      'en-hk': 'en',
      'en-ie': 'en',
      'en-il': 'en',
      'en-im': 'en',
      'en-in': 'en',
      'en-jm': 'en',
      'en-mh': 'en',
      'en-fm': 'en',
      'en-mp': 'en',
      'en-pk': 'en',
      'en-ph': 'en',
      'en-sg': 'en',
      'en-sx': 'en',
      'en-tc': 'en',
      'en-vi': 'en',
      'en-vg': 'en',
      'en-za': 'en',
      'en-zw': 'en',
      // Adicione outras variantes de idioma conforme necessário...
    };
    
    //console.log(detectedLanguage.toLowerCase());
    if (languageMap[detectedLanguage.toLowerCase()]) {
      setLanguage(languageMap[detectedLanguage.toLowerCase()]);
    } else {
      setLanguage('pt');
    }
  }, [detectedLanguage]);*/

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const contextValue = {
    language,
    texts,
    changeLanguage,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};
