import React, { useState } from "react";
import PhoneInput from 'react-phone-number-input';
import './style.css';

import { useLanguage } from '../../components/lang/langcontext.js';

import ApiListJson from '../../components/infoapi/apilist.json';

import '../../components/generalstyles/phoneinput_style.css';
import { border } from "@mui/system";

export default function App() {
  const [phoneValue, setPhoneValue] = useState();
  const { language, texts, changeLanguage } = useLanguage();
  const Lang = texts[language];

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    wherefrom: '',
    resume: null,
    message: '',
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'resume') {
      setFormData({
        ...formData,
        resume: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
        phone: phoneValue,
      });
    }
  };

  function changeInputEnabled(isEnabled) {
    const inputs = document.querySelectorAll('.workwithus_defaultinput');
    const inputs2 = document.querySelectorAll('.workwithus_defaultinput input');
    const inputs3 = document.querySelectorAll('.workwithus_defaultinput select');
  
    inputs.forEach(input => {
      input.disabled = !isEnabled;
    });
    inputs2.forEach(input2 => {
      input2.disabled = !isEnabled;
    });
    inputs3.forEach(input3 => {
      input3.disabled = !isEnabled;
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const ApiURL = ApiListJson.workwithusapi;
    const MasterWorkWithUsForm = document.getElementById("MasterWorkWithUsForm");
    const sendmessage_aboutbtn_workwithus = document.getElementById("sendmessage_aboutbtn_workwithus");
    const sendmessage_aboutbtn_workwithus_loading = document.getElementById("sendmessage_aboutbtn_workwithus_loading");
    const workrwithus_formalert = MasterWorkWithUsForm.querySelector(".formalert");

    MasterWorkWithUsForm.classList.remove("formshowalert");
    workrwithus_formalert.querySelector("p").textContent = "";
    workrwithus_formalert.classList.remove("error");
    workrwithus_formalert.classList.remove("alert");
    workrwithus_formalert.classList.remove("success");

    changeInputEnabled(false);
    sendmessage_aboutbtn_workwithus_loading.classList.remove("hide");
    sendmessage_aboutbtn_workwithus.classList.add("hide");

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    const xhr = new XMLHttpRequest();
    xhr.open('POST', ApiURL);

    xhr.onreadystatechange = function () {
      try {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status !== 200) {
            const response = JSON.parse(xhr.responseText);
            if (response.error) {
              changeInputEnabled(true);
              MasterWorkWithUsForm.classList.add("formshowalert");
              workrwithus_formalert.classList.add("error");
              workrwithus_formalert.querySelector("p").textContent = `${Lang.workwithus.formalerts.alert2} ${response.error} ${Lang.workwithus.formalerts.alert2_2}`;
              sendmessage_aboutbtn_workwithus.classList.remove("hide");
              sendmessage_aboutbtn_workwithus_loading.classList.add("hide");
            } else {
              if (xhr.status === 429) {
                changeInputEnabled(true);
                MasterWorkWithUsForm.classList.add("formshowalert");
                workrwithus_formalert.classList.add("error");
                workrwithus_formalert.querySelector("p").textContent = `${Lang.workwithus.formalerts.alert4}`;
                sendmessage_aboutbtn_workwithus.classList.remove("hide");
                sendmessage_aboutbtn_workwithus_loading.classList.add("hide");
              } else {
                changeInputEnabled(true);
                MasterWorkWithUsForm.classList.add("formshowalert");
                workrwithus_formalert.classList.add("error");
                workrwithus_formalert.querySelector("p").textContent = Lang.workwithus.formalerts.alert3;
                sendmessage_aboutbtn_workwithus.classList.remove("hide");
                sendmessage_aboutbtn_workwithus_loading.classList.add("hide");
              }
            }
          } else {
            const data = JSON.parse(xhr.responseText);
            if (data.success && data.message === 'msg_ok') {
              changeInputEnabled(true);
              MasterWorkWithUsForm.classList.add("formshowalert");
              workrwithus_formalert.classList.add("success");
              workrwithus_formalert.querySelector("p").textContent = Lang.workwithus.formalerts.alert1;
              sendmessage_aboutbtn_workwithus.classList.remove("hide");
              sendmessage_aboutbtn_workwithus_loading.classList.add("hide");
            } else {
              changeInputEnabled(true);
              MasterWorkWithUsForm.classList.add("formshowalert");
              workrwithus_formalert.classList.add("error");
              workrwithus_formalert.querySelector("p").textContent = Lang.workwithus.formalerts.alert3;
              sendmessage_aboutbtn_workwithus.classList.remove("hide");
              sendmessage_aboutbtn_workwithus_loading.classList.add("hide");
            }
          }
        }
      } catch(error) {
        changeInputEnabled(true);
        MasterWorkWithUsForm.classList.add("formshowalert");
        workrwithus_formalert.classList.add("error");
        workrwithus_formalert.querySelector("p").textContent = Lang.workwithus.formalerts.alert3;
        sendmessage_aboutbtn_workwithus.classList.remove("hide");
        sendmessage_aboutbtn_workwithus_loading.classList.add("hide");
      }
    };

    xhr.onerror = function (error) {
      sendmessage_aboutbtn_workwithus.classList.remove("hide");
      sendmessage_aboutbtn_workwithus_loading.classList.add("hide");
      changeInputEnabled(true);
      MasterWorkWithUsForm.classList.add("formshowalert");
      workrwithus_formalert.classList.add("error");
      workrwithus_formalert.querySelector("p").textContent = Lang.workwithus.formalerts.alert3;
    };

    xhr.send(formDataToSend);
  };

  return (
    <div className="App WorkWithUsAppPage">
      <h2>{Lang.workwithus.pagetitle}</h2>
      <form autoComplete="off" className="MasterWorkWithUsForm" id="MasterWorkWithUsForm" onSubmit={handleSubmit}>
        <div className="formalert">
          <p></p>
        </div>
        <div>
          <label htmlFor="name">
            {Lang.workwithus.inputs.name.span}
          </label>
          <input
            type="text"
            name="name"
            className="workwithus_defaultinput"
            defaultValue={formData.name}
            placeholder={Lang.workwithus.inputs.name.placeholder}
            onChange={handleChange}
            required
          />
        </div>
        <div style={{display: "flex", width: "100%", gap: "16px"}}>
          <div style={{width: "100%"}}>
            <label htmlFor="phone">
                {Lang.workwithus.inputs.phone.span}
            </label>
            <PhoneInput
                type="text"
                name="phone"
                className="workwithus_defaultinput"
                placeholder={Lang.workwithus.inputs.phone.placeholder}
                defaultValue={phoneValue}
                onChange={setPhoneValue}
                limitMaxLength={true}
                defaultCountry={Lang.countrycode}
                required
            />
          </div>
          <div style={{width: "100%"}}>
            <label htmlFor="email">
                {Lang.workwithus.inputs.email.span}
            </label>
            <input
                type="text"
                name="email"
                className="workwithus_defaultinput"
                defaultValue={formData.email}
                placeholder={Lang.workwithus.inputs.email.placeholder}
                onChange={handleChange}
                required
            />
          </div>
        </div>
        <div className="selectmaster">
          <label htmlFor="wherefrom">{Lang.workwithus.inputs.wherefrom.span}</label>
          <select
            name="wherefrom"
            className="workwithus_defaultinput"
            defaultValue={formData.wherefrom}
            onChange={handleChange}
          >
            <option value="Não Especificado">{Lang.workwithus.inputs.wherefrom.selects.s1}</option>
            <option value="Redes Sociais">{Lang.workwithus.inputs.wherefrom.selects.s2}</option>
            <option value="Amigos">{Lang.workwithus.inputs.wherefrom.selects.s3}</option>
            <option value="Sites">{Lang.workwithus.inputs.wherefrom.selects.s4}</option>
            <option value="Eventos">{Lang.workwithus.inputs.wherefrom.selects.s5}</option>
            <option value="E-mails">{Lang.workwithus.inputs.wherefrom.selects.s6}</option>
            <option value="Outro">{Lang.workwithus.inputs.wherefrom.selects.s7}</option>
          </select>
        </div>
        <div>
          <label htmlFor="resume">{Lang.workwithus.inputs.resume.span}</label>
          <input
            type="file"
            name="resume"
            className="workwithus_defaultinput"
            accept=".pdf,.doc,.docx,.odt,.png,.jpg,.jpeg"
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="message">{Lang.workwithus.inputs.message.span}</label>
          <textarea
            style={{minHeight: "120px", resize: "vertical"}}
            name="message"
            className="workwithus_defaultinput"
            defaultValue={formData.message}
            placeholder={Lang.workwithus.inputs.message.placeholder}
            onChange={handleChange}
            required
          />
        </div>
        <button id="sendmessage_aboutbtn_workwithus" className="workwithus_defaultinput" type="submit">
          {Lang.workwithus.inputs.sendbtn}
        </button>
        <button id="sendmessage_aboutbtn_workwithus_loading" className="hide workwithus_defaultinput" type="submit">
          <span className="loader"></span>
        </button>
      </form>
    </div>
  );
}
