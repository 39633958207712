// App.js
import React, { useState, useEffect } from 'react';
import './style.css';

import { useLanguage } from '../../components/lang/langcontext.js';

import slidesData from '../../components/lang/slidesdata.json';

const App = () => {
  const { slides } = slidesData; // Extrair os slides do JSON

  const { language, texts, changeLanguage } = useLanguage();
  const Lang = texts[language];

  const initialSlide = Math.floor((slides.length - 1) / 2);
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [timeRemaining, setTimeRemaining] = useState(8000); // Tempo inicial

  const handleNextSlide = () => {
    const nextSlide = (currentSlide + 1) % slides.length;
    setCurrentSlide(nextSlide);
    setTimeRemaining(8000); // Reiniciar o tempo ao trocar de slide
  };

  const handlePrevSlide = () => {
    const prevSlide = (currentSlide - 1 + slides.length) % slides.length;
    setCurrentSlide(prevSlide);
    setTimeRemaining(8000); // Reiniciar o tempo ao trocar de slide
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
    setTimeRemaining(8000); // Reiniciar o tempo ao trocar de slide
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => Math.max(0, prevTime - 1000));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentSlide]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleNextSlide();
    }, timeRemaining);

    return () => clearTimeout(timer);
  }, [timeRemaining, currentSlide]);

  const dotbgWidth = (timeRemaining / 8000) * 100; // Calcula a largura do dotbg

  return (
    <div className='App'>
        <div className='HomeMain_BannerSlides'>
            <div className="app-container">
                <div className="banner-container" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {slides.map((slide, index) => (
                  <div
                    key={index}
                    className={`banner-slide ${index === currentSlide ? 'active' : ''}`}
                    style={{
                      backgroundImage: `url(${slide.url})`,
                      transform: `scale(${index === currentSlide ? 1.01 : 0.97})`,
                      opacity: index === currentSlide ? 1 : 0.4,
                    }}
                  >
                    <div className='bannerinfo'>
                      <p className='title'>{slide.title[language]}</p>
                      <p className='desc'>{slide.description[language]}</p>
                    </div>
                  </div>
                ))}
                </div>
                <div className="button-container">
                <button onClick={handlePrevSlide} className="nav-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
                    </svg>
                </button>
                <button onClick={handleNextSlide} className="nav-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                    </svg>
                </button>
                </div>
                <div className="dots-container">
                {slides.map((_, index) => (
                    <div
                    key={index}
                    className={`dot ${index === currentSlide ? 'active' : ''}`}
                    onClick={() => handleDotClick(index)}
                    >
                    <div className='dotbg' style={{ width: `${dotbgWidth}%`, transition: 'width 1s ease-in-out' }}></div>
                    </div>
                ))}
                </div>
            </div>
        </div>
        <div className='HomeMain_Info'>
          <p>{Lang.home.info}</p>
        </div>
        <div className='HomeMain_Services'>
            <div className='HomeMain_Title'>
              <p>{Lang.home.servicestitle}</p>
            </div>
            {/**/}
            <div className='HomeServices_List'>
              <div className='Services_ListItem'>
                {/* IMPORTAÇÃO INFO */}
                <div style={{backgroundImage: "url('https://i.imgur.com/9tAcufw.png')"}} className='itemimg'>
                  <div className='imgtitle'>
                    <p>{Lang.home.services_imptitle}</p>
                  </div>
                </div>
                <div className='iteminfo'>
                  <p className='mobiletitle'>{Lang.home.services_imptitle}:</p>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text1}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text2}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text3}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text4}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text5}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text6}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text7}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text8}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text9}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text10}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text11}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_impinfo.text12}</p>
                  </div>

                </div>

              </div>
              {/**/}
              <div className='Services_ListItem rightitem'>
                {/* EXPORTAÇÃO INFO */}
                <div style={{backgroundImage: "url('https://i.imgur.com/qSJpr7U.jpg')"}} className='itemimg'>
                  <div className='imgtitle'>
                    <p>{Lang.home.services_exptitle}</p>
                  </div>
                </div>
                <div className='iteminfo'>
                  <p className='mobiletitle'>{Lang.home.services_exptitle}:</p>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text1}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text2}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text3}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text4}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text5}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text6}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text7}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text8}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text9}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text10}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text11}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_expinfo.text12}</p>
                  </div>
                 

                </div>

              </div>
              {/**/}
              <div className='Services_ListItem'>
                {/* CONSULTORIA INFO */}
                <div style={{backgroundImage: "url('https://i.imgur.com/qCgMH28.png')"}} className='itemimg'>
                  <div className='imgtitle'>
                    <p>{Lang.home.services_constitle}</p>
                  </div>
                </div>
                <div className='iteminfo'>
                  <p className='mobiletitle'>{Lang.home.services_constitle}:</p>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_consinfo.text1}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_consinfo.text2}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_consinfo.text3}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_consinfo.text4}</p>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                    </svg>
                    <p>{Lang.home.services_consinfo.text5}</p>
                  </div>

                </div>

              </div>

            </div>
        </div>
        
    </div>
  );
};

export default App;
